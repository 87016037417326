import clsx from 'clsx'

import { Icon } from '@fv/client-components'

import { useHasUnreadMessages } from './hooks/useHasUnreadMessages'

export type Props = {
  messageSummary?: {
    lastCarrierMessageDate?: string
    lastReadDate?: string
  }
}
const NewMessageIndicator = ({ messageSummary }: Props) => {
  const hasUnreadMessages = useHasUnreadMessages(messageSummary)

  if (!hasUnreadMessages) {
    return null
  }

  return (
    <a
      className={clsx('shipment-card__message-icon rounded-tr-lg')}
      href="#"
      onClick={e => e.preventDefault()}
      title="You have new messages"
    >
      <Icon
        className="pulse-with-color"
        icon="comment-alt-dots"
        transform="down-1"
      />
    </a>
  )
}

export default NewMessageIndicator
