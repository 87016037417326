import clsx from 'clsx'
import orderBy from 'lodash/orderBy'
import { useState } from 'react'

import { FvLinkButton } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import {
  type FilterType,
  MiniQuoteList,
} from '../../features/mini-quote-list/MiniQuoteList'
import { NoteList } from './NoteList'
import { ShipmentTags } from './ShipmentTags'

type PanelName = 'messages' | 'notes' | 'tags'
type MessagesNotesTagsPanelProps = {
  closePanel?: () => void
  load: FullShipment
  className?: string
  navClass?: string
  contentClass?: string
}

export const MessagesNotesTagsControls = ({
  closePanel,
  load,
  className,
  navClass,
  contentClass,
}: MessagesNotesTagsPanelProps) => {
  const [activePanel, setActivePanel] = useState<PanelName>('messages')
  const [messageFilter, setMessageFilter] = useState<FilterType>('active')
  const { loadId, selectedQuote } = load

  return (
    <div className={className}>
      <nav
        className={clsx('divided-content', 'divided-content--start', navClass)}
      >
        {closePanel && (
          <FvLinkButton icon="arrow-to-left" onClick={closePanel}>
            Back
          </FvLinkButton>
        )}
        <FvLinkButton
          className={clsx({
            'active-hint-indicator': activePanel === 'messages',
          })}
          onClick={() => setActivePanel('messages')}
          icon="comment-alt"
          transform="down-1"
        >
          Messages
        </FvLinkButton>
        <FvLinkButton
          className={clsx({
            'active-hint-indicator': activePanel === 'notes',
          })}
          onClick={() => setActivePanel('notes')}
          icon="sticky-note"
        >
          Notes
        </FvLinkButton>
        <FvLinkButton
          className={clsx({
            'active-hint-indicator': activePanel === 'tags',
          })}
          onClick={() => setActivePanel('tags')}
          icon="tags"
        >
          Tags
        </FvLinkButton>
      </nav>
      <div className={clsx('overflow-auto', contentClass)}>
        {activePanel === 'messages' && (
          <div>
            <div className="divided-content divided-content--start text-sm mb-4">
              <FvLinkButton
                icon="comment-alt-dots"
                onClick={() => setMessageFilter('active')}
                className={clsx({
                  'active-hint-indicator': messageFilter === 'active',
                })}
              >
                Active
              </FvLinkButton>
              <FvLinkButton
                icon="comment-alt-minus"
                onClick={() => setMessageFilter('pending')}
                className={clsx({
                  'active-hint-indicator': messageFilter === 'pending',
                })}
              >
                Inactive
              </FvLinkButton>
              <FvLinkButton
                icon="comment-alt-slash"
                onClick={() => setMessageFilter('declined')}
                className={clsx({
                  'active-hint-indicator': messageFilter === 'declined',
                })}
              >
                Declined
              </FvLinkButton>
            </div>

            <MiniQuoteList
              filter={messageFilter}
              key={selectedQuote?._id}
              load={load}
              readOnly
              initialActiveId={selectedQuote?._id}
              sort={r =>
                orderBy(
                  r,
                  [r => r.status === 'awarded', r => r.amount, r => r.name],
                  ['desc', 'desc', 'asc'],
                )
              }
            />
          </div>
        )}

        {activePanel === 'notes' && <NoteList loadId={loadId} />}

        {activePanel === 'tags' && <ShipmentTags loadId={loadId} />}
      </div>
    </div>
  )
}
